$(function () {
    let processed = false;
    $("body").on("tasks:loaded", function () {
        const bookingId = $('#taskdata').find('div.panel-collapse').attr('id');
        $('#departure-message').load('/xperitas/departure-message/' + bookingId);
    })
        .on("form:saved", function (e, data) {
            if (data.hasOwnProperty('task') && data.hasOwnProperty('status') && !processed) {
                if (data.status === 'complete') {
                    $('#message-text').html(
                        'If you need to make updates after submitting your health information, ' +
                        'please contact <a href="mailto:registration@xperitas.org">registration@xperitas.org</a>'
                    )
                    $('#message').addClass('alert-success').show("slow").delay(10000).hide("slow");
                    processed = true;
                }
            }
        });
});
